<template>
  <div :class="`${!isMobileDevice ? 'player-container' : 'player-container-mobile'}`">
    <div class="flex justify-center">
      <ImageResize class="h-50 mx-auto only-xl:h-80 object-contain" :src="player.img" type="player_modal" />
    </div>
    <div :class="`${!isMobileDevice ? 'buy-form' : 'buy-form-mobile'} flex flex-col flex-1 px-11-7 pb-11-7 rounded-24 xl:rounded-b-none md:px-4`">
      <div class="text-center text-xl24 font-bold md:text-xl22">{{$t('player.purchase_confirmed')}}</div>

      <div class="purchase-container">
        <div class="check-icon">
          <IconCheck class="h-5" />
        </div>
        <div class="flex justify-between items-center">
          <div class="font-semibold">{{$t('player.purchase_amount')}}</div>
          <div class="font-bold text-text-positive">${{numberFormat(amountUsd)}}</div>
        </div>
        <div class="flex justify-between items-center mt-3-9">
          <div class="font-semibold">{{$t('player.trade_fee')}}</div>
          <div class="font-semibold">${{numberFormat(amountUsdFee)}}</div>
        </div>
      </div>

      <PlayerCardsList
        class="mt-7-8"
        :player="player"
        :amountUsd="amountUsd"
        :cards="cards"
      />

      <div class="mt-auto">
        <Button
          @buttonAction="hideModal()"
          :label="$t('player.done')"
          customClasses="flex items-center justify-center mt-7 pl-7-5 pr-7-5 h-15-1 w-full bg-background-positive-text text-text-positive text-n-lg font-bold rounded-15"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import IconCheck from '@/assets/icons/check.svg';
  import PlayerCardsList from '@/components/player/PlayerCardsList';
  import { ImageResize } from '@/components/misc';
  import Button from '@/components/misc/Button';

  export default {
    name: 'BuyPlayerConfirmation',
    components: {
      IconCheck,
      PlayerCardsList,
      ImageResize,
      Button,
    },
    props: {
      player: {
        type: Object,
        required: true,
      },
      amountUsd: {
        type: String,
        required: true,
      },
      amountUsdFee: {
        type: Number,
        required: true,
      },
      cards: {
        type: Array,
        required: true,
      },
    },

    async mounted() {
      const player = this.$store.state.api['player'];

      await this.$store.dispatch('events/track', {
        event: 'PLAYER_CHECKOUT_RECEIPT_VIEWED',
        variables: {
          id: player.id,
          id_hr: player.id_hr,
          sport: player.sport ? player.sport.id_hr : '',
          team: player.team ? player.team.name : '',
          total_value: player.total_value,
          asset_count: player.asset_count,
          change_24h: player.change_24h,
          change_24h_pct: player.change_24h_pct,
          amount: this.amountUsd,
          transaction_type: 'buy',
        },
      });
    },
  };
</script>

<style scoped>
.player-container {
  @apply pt-11-7 px-11-7 -mx-4 min-w-sm;
  margin-bottom: -1.12rem;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 20px;
  height: min(calc(100vh - 25px), 841px);
}
.player-container-mobile {
  @apply flex flex-1 flex-col pt-7 px-7 mt-auto;
  margin-bottom: -1.12rem;
  height: min(calc(100vh - 25px), 841px);
}
.buy-form {
  @apply bg-background-primary -mx-11-8 pt-7-8;
}
.buy-form-mobile {
  @apply bg-background-primary -mx-7 pt-4;
}
.purchase-container {
  @apply flex flex-col relative bg-background-primary px-5 pt-18-5 pb-5 mt-24 border border-border;
  border-radius: 15px;
}
.check-icon {
  @apply flex justify-center items-center absolute bg-background-positive-text;
  top: -77px;
  left: calc(50% - 77px);
  height: 134px;
  width: 134px;
  border-radius: 50%;
}
.check-icon svg {
  width: 64px;
  height: 50px;
}
</style>
